import { useEffect, useState } from 'react';
import { useApi } from '../contexts/api.context';
import jwt_decode from 'jwt-decode';

export default function useAuthStatus() {
  const apiService = useApi();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idTokenResult, setIdTokenResult] = useState(null);
  const [hasLoadedAuth, setHasLoadedAuth] = useState(false);

  useEffect(() => {
    let isMounted = true;

    apiService.onTokenChanged().subscribe(async token => {
      if(isMounted) {
        const decoded = token ? jwt_decode(token) : null;
        setIdTokenResult(decoded);
        setIsAuthenticated(!!token);
        setHasLoadedAuth(true);
      }
    });

    return () => { isMounted = false; };
  }, []);

  return { isAuthenticated, hasLoadedAuth, idTokenResult };
}